import React from "react"
import {
  Hero,
  Services,
  About,
  LatestProject,
  Projects,
  Banner,
  Contact,
} from "../components/sections"
import { Layout } from "../components/layout"
import { Seo } from "../components/common"

const HomePage = () => {
  return (
    <>
      <Seo title={"Home"} />
      <Layout>
        <Hero />
        <Services />
        <About />
        <LatestProject />
        <Banner />
        <Projects />
        <Contact />
      </Layout>
    </>
  )
}

export default HomePage
